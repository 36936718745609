<template>
  <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 0H0V4.66875H24V0Z" fill="#F4F4F4"/>
    <path d="M24 4.66406H0V9.33281H24V4.66406Z" fill="#324095"/>
    <path d="M24 9.33282H0V14.0016H24V9.33282Z" fill="#D81F26"/>
  </svg>

</template>

<script>
export default {
  name: 'Ru'
}
</script>
